<template>
  <div id="detail" :class="{karamucho: page==='karamucho', koimucho: page==='koimucho', webp: isSupportWebp}" data-view>
    <DetailNavigation :page="page" @changeLocale="changeLocale" :locale="locale"/>
    <Top :page="page" />
    <kinesis-container class="main" :perspective="2000" :duration="1000" :style="{backgroundImage: 'url(' + webp(require(`../public/img/detail/${page}/bg-content.jpg`)) + ')' }">
      <kinesis-element :strength="100" type="translate" class="main-paralax pc-appear" tag="div">
        <img class="lazyload" :data-src="webp(require(`../public/img/detail/${page}/paralax.png`))" alt="">
      </kinesis-element>
      <Intro @showGallery="galleryData" :page="page"/>
      <!-- <Promotion id="promotion" @showGallery="stickerImg" :windowWidth="windowWidth"/> -->
      <History :page="page"/>
      <Product :page="page" :locale="locale"/>
    </kinesis-container>
    <Footer :page="page"/>
    <div class="lightbox" :class="{open: lightBoxOpen, sticker}">
      <div class="lightbox-close" @click="closeLightBox()"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249	C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306	C514.019,27.23,514.019,14.135,505.943,6.058z"/></g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636 c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"/></g></svg></div>
      <div class="lightbox-control lightbox-control--prev" :class="{disable: imgID===1}" @click="imgID--"></div>
      <div class="lightbox-control lightbox-control--next" :class="{disable: imgID===totalIMG}" @click="imgID++"></div>
      <div class="lightbox-total"><span>{{imgID}}</span><span>/</span><span>{{totalIMG}}</span></div>
      <div class="lightbox-content">
        <div class="lightbox-inner" v-if="lightBoxType==='intro'">
          <img class=""
            :src="webp(require(`../public/img/detail/${page}/gallery/${imgID}.png`))"
            :class="{question: page==='koimucho'}"
            alt="">
          <img class=""
            :src="webp(require(`../public/img/detail/${page}/gallery/${imgID}_answer.png`))"
            :class="{answer: page==='koimucho'}"
            v-if="page==='koimucho'"
            alt="">
        </div>
        <div class="lightbox-inner" v-else-if="lightBoxType==='sticker'">
          <img class=""
            :src="webp(require(`../public/img/promotion/sticker/sticker-${imgID}.png`))"
            alt="">
        </div>    
      </div>
    </div>
    <!-- <Popup :windowWidth="windowWidth" :link="linkPopup" :locale="locale"  :page="page" v-show="page === 'koimucho'"/> -->
  </div>
</template>

<script>
  const Top = () => import('./components/detail/Top');
  const DetailNavigation = () => import('./components/detail/DetailNavigation');
  //const Promotion = () => import('./components/detail/Promotion')
  //import loading from './components/detail/loading'
  import { KinesisContainer, KinesisElement } from 'vue-kinesis';
  //import i18n from "./plugins/detail/i18n"

  import 'lazysizes'

  const WebFont = require('webfontloader')

  import lazyLoadComponent from "./utils/lazy-load-component";
  //import SkeletonBox from "./components/SkeletonBox";
  import Popup from './components/Popup'
  import {scroller} from 'vue-scrollto/src/scrollTo'
  export default {
    name: 'Detail',
    metaInfo () {
      var metaInfo = {
        karamucho: {
          title: 'KARAMUCHO | Koikeya',
          description: 'KARAMUCHO là thương hiệu snack cay bán chạy số 1* Nhật Bản. Sản phẩm là sự pha trộn tuyệt vời giữa vị nóng, cay, chua và ngọt.'
        },
        koimucho: {
          title: 'KoiMUCHO | Koikeya',
          description: 'KoiMUCHO là thương hiệu snack không cay của công ty Koikeya, rất được ưa chuộng ở Nhật Bản. '
        }
      };
      return {
        title: metaInfo[this.page].title,
        meta: [
          { name: 'description', content: metaInfo[this.page].description, vmid: 'description' },
          { property: 'og:title', content: metaInfo[this.page].title, vmid: 'og:title' },
          { property: 'og:description', content: metaInfo[this.page].description, vmid: 'og:description'},
        ]
      }
    },
    components: {
      Top,
      DetailNavigation,
      Intro: () => import('./components/detail/Intro'),
      //Promotion: () => import('./components/detail/Promotion'),
      History: () => import('./components/detail/History'),
      Product: () => import('./components/detail/Product'),
      Footer: () => import('./components/detail/Footer'),
      KinesisContainer,
      KinesisElement,
      //Popup
    },
    data() {
      return {
        page: 'koimucho',
        linkPopup: 'javascript:void(0)',
        locale: localStorage.locale || 'vi',
        lightBoxOpen: false,
        lightBoxType: '',
        totalIMG: 0,
        imgID: 1,
        sticker: false,
        windowWidth: 0,
      }
    },
    created() {
      this.getURLParam()
      this.getWindowWidth()
    },
    mounted() {
      if(this.isMobile) {
        document.querySelector('html').classList.add('mobile');
      }
      window.addEventListener('resize', this.getWindowWidth);

      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          document.querySelector('html').classList.remove('loading')

          WebFont.load({
            google: {
              families: [
                'Open Sans:400,700',
                'Baloo Bhai:400,700&display=swap'
              ]
            }
          })
          document.querySelector('html').classList.remove('loading')
        }
        this.scrollTo()
      }
    },
    computed: {
      isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      }
    },
    watch: {
      
    },
    methods: {
      galleryData: function(total, id, type) {
        let windowWidth = window.outerWidth
        this.totalIMG = total
        this.imgID = id
        this.lightBoxType = type

        if (windowWidth >= 600) {
          this.lightBoxOpen = true
        }
      },
      stickerImg(id, total) {
        this.imgID = id
        this.totalIMG = total
        this.lightBoxType = 'sticker'
        this.sticker = true
        this.lightBoxOpen = true
      },
      closeLightBox() {
        this.lightBoxOpen = false
        this.sticker = false
        if (this.lightBoxType === 'sticker') 
          this.$store.commit('setStickerID', this.imgID)
      },
      getURLParam() {
        this.page = this.$route.params.product
      },
      getWindowWidth() {
        this.windowWidth = window.innerWidth
      },
      changeLocale(locale) {
        this.$i18n.locale = locale
        this.locale = locale
        localStorage.locale = this.locale
      },
      scrollTo() {
        if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';
        }
        const hash = window.location.hash
        if(hash === '#promotion') {
          var VueScrollTo = require('vue-scrollto')
          VueScrollTo.scrollTo('#promotion', 0, {
            offset: this.isMobile ? -110 : -130
          })
        }
      }
    }
  }
</script>

<style lang="scss">
@import './assets/scss/all.scss';
  html {
    &.mobile {
      overflow-x: hidden;
    }
  }
  body {
    overflow-x: hidden;
    height: 100%;
  }
  
  #detail {
    position: relative;
  }
  .karamucho {
    .main {
      background: repeat-y top center;
    }
  }
  .koimucho {
    .main {
      background: repeat-y top center;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 19%;
        width: 25%;
        height: 20%;
        background: url(../public/img/detail/bui.png) no-repeat left top / contain;
        z-index: 9;
        @include media(phone) {
          display: none;
        }
      }
      &:after {
        @include media(phone) {
          content: "";
          position: absolute;
          right: 15%;
          top: -4.5rem;
          height: 9rem;
          width: 9rem;
          background: url(../public/img/detail/koimucho/img-leaf.png) no-repeat center / contain;
          z-index: 9;
        }
      }
      &-paralax {
        width: 100%;
        left: 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .container {
    width: 125rem;
    padding: 0 2rem;
    max-width: 100%;
    margin: 0 auto;
    @include media (set-max-w, 1366) {
      width: 110rem;
    }
  }
  .main {
    width: 100%;
    position: relative;
    padding-bottom: 16rem;
    z-index: 2;
    @include media(tablet) {
      padding-bottom: 0;
    }
    @include media(phone) {
      padding-bottom: 0;
    }
    &-paralax {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
    }
  }
  .lightbox {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000000, .8);
    z-index: -1;
    display: none;
    &.open {
      display: block;
      z-index: 9999;
    }
    &.sticker {
      @include media(set-max-w, 800) {
        .lightbox-close {
          width: 2rem;
        }
        .lightbox-control {
          top: initial;
          bottom: 0;
          background-size: 5rem;
        }
        .lightbox-inner{
          height: initial;
          text-align: center;

          img {
            height: auto;
            width: 90%;
          }
        }
      }
    }
    &-close {
      position: fixed;
      top: 2rem;
      right: 2rem;
      width: 3rem;
      height: 3rem;
      z-index: 99999;
      cursor: pointer;
      svg {
        fill: #fff
      }
    }
    &-control {
      position: fixed;
      top: 50%;
      z-index: 99999;
      font-size: 3rem;
      color: #fff;
      width: 8rem;
      height: 3rem;
      background: url(../public/img/icon/icon-arrow-half-white.png) no-repeat center / contain;
      cursor: pointer;
      &--next {
        right: 2rem;
        transform: translateY(-50%) rotate(-180deg);
      }
      &--prev {
        left: 2rem;
        transform: translateY(-50%) ;
      }
    }
    &-total {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #fff;
      font-size: 2rem;
      text-align: center;
      font-weight: bold;
      padding: 1rem;
      span {
        padding: 0 .5rem;
      }
    }
    &-content {
      position: relative;
      width: 74rem;
      height: 100vh;
      max-width: 100%;
      padding: 0 1.5rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-inner {
      position: relative;
      height: 80vh;
      img {
        height: 100%;
        width: auto;
      }
      &:hover {
        .question {
          opacity: 0;
        }
      }
      .question {
        opacity: 1;
        transition: opacity .2s ease-in-out;
      }
      .answer {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }
  }
</style>