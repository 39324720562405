<template>
  <div class="fpopup" :class="{min, sticker}">
    <div class="fpopup-bg" @click="minPopup()"></div>
    <div class="fpopup-content">
      <div class="fpopup-wrapper">
        <div class="fpopup-close" @click="minPopup()"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249	C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306	C514.019,27.23,514.019,14.135,505.943,6.058z"/></g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636 c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"/></g></svg></div>
        <div class="fpopup-inner" >
          <div class="fpopup-full fpopup-row">
            <div class="fpopup-left">
              <a href="javascript:void(0)" @click="linkTo()"><img :src="webp(require(`../../public/img/promotion/${isPage}-banner.png`))" alt=""></a>
            </div>
            <div class="fpopup-right">
              <div class="fpopup-right__inner">
                <div class="introduction"><img :src="webp(require(`../../public/img/promotion/${locale}-text.jpg`))" alt=""></div>
                <div class="fpopup-right__video">
                  <youtube class="youtube" :video-id="youtubeID" ref="youtube"></youtube>
                  <!-- <iframe class="youtube" src="https://www.youtube.com/embed/" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                </div>
              </div>
            </div>
          </div>
          <div class="fpopup-min">
            <a href="javascript:void(0)" @click="linkTo()">
              <img @click="fullPopup()" :src="webp(require(`../../public/img/promotion/${device}-${isPage}-banner-min.png`))" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'fPopup',
  // components: {
  //   Youtube
  // },
  props: {
    windowWidth: Number,
    page: String,
    link: String,
    locale: String
  },
  data() {
    return {
      type: 'img',
      youtubeID: 'wPyrhbdUE0o'
    }
  },
  computed: {
    ...mapState([
      'min',
      'sticker',
    ]),
    device() {
      return this.windowWidth > 800 ? 'pc' : 'sp'   
    },
    isPage() {
      return this.page === 'home' ? 'home' : 'sub'
    },
    player() {
      return this.$refs.youtube.player
    }
  },
  methods: {
    linkTo() {
      if (this.page === 'home') {
        this.$store.dispatch('minimizePopup')
        window.location.href = '/product/koimucho/#promotion'
      }
    },
    minPopup() {
      this.$store.dispatch('minimizePopup')
      this.player.pauseVideo()
    },
    fullPopup() {
      this.$store.dispatch(this.page === 'home' ? 'minimizePopup' : 'maximizePopup')
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/mixin/_mixin';

  .fpopup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    transition: all .5s ease;

    &.min {
      bottom: 0;
      left: 0;
      top: initial;
      right: initial;
      width: 100%;
      
      .fpopup-bg {
        background: none
      }
      .fpopup-close {
        display: none
      }
      .fpopup-content {
        padding: 0;
        top: initial;
        bottom: 0;
        transform: translate(-50%, 0);
        width: 100%
      }
      .fpopup-wrapper {
        padding: 0;
        width: initial
      }
      .fpopup-full {
        max-height: 0
      }
      .fpopup-min {
        max-height: 100rem
      }
    }
    &.sticker {
      .fpopup-left,
      .introduction {
        display: none;
      }
      .fpopup-right {
        width: 100%;
      }
      .fpopup-wrapper {
        padding: 0;
      }
    }
    &-img {
      .fpopup-wrapper {
        width: 80rem;
        max-width: 100%;

        @include media(set-max-w, 800) {
          width: 70rem;
        }
        .fpopup-inner {
          text-align: center;
        }
      }
      img {
        cursor: pointer;
      }
    }
    &-video {
      .fpopup-wrapper {
        width: 80rem;
        padding-top: 56.25%;
        max-width: 100%;
      }
    }
    &-text {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      
      @include media(set-max-w, 767) {
        font-size: 1.4rem;
      }
      .fpopup-wrapper {
        padding: 5rem 4rem;
        background: #ffffff;
        width: 95rem;
        max-width: 100%;
        border: 4px solid #327c0d;
        border-radius: 2rem;
        box-shadow: 0 0 15px rgba(#ffffff, .3);

        @include media(set-max-w, 767) {  
          padding: 2rem;
        }
      }
    }
    &-bg {
      background: rgba(#000000, .6);
      width: 100%;
      height: 100%;
    }
    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      padding: 0 2rem;
    }
    &-wrapper {
      width: 116rem;
      max-width: 100%;
      position: relative;
      background: #ffffff;
      padding: 2rem
    }

    &-close {
      position: absolute;
      display: flex;
      align-items: center;
      width: 4rem;
      height: 4rem;
      right: -2rem;
      top: -2rem;
      padding: 1rem;
      z-index: 9999;
      cursor: pointer;
      background: #ffffff;
      border-radius: 50%;

      @include media(set-max-w, 767) {
        width: 3rem;
        height: 3rem;
        top: -1.5rem;
        right: -1.5rem;
      }
      svg {
        fill: #327c0d;
        width: 2rem;
      }
    }
    &-full {
      overflow: hidden;
      max-height: 100rem;
    }
    &-min {
      max-height: 0;
      overflow: hidden;
      
      img {
        cursor: pointer;
      }
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
    }
    &-left,
    &-right {
      width: 50%;
      padding: 0 1rem;

      @include media(set-max-w, 800) {
        width: 100%;
      }
    }
    &-right {
      
      &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &__video {
        position: relative;
        padding-top: 56.25%;
      }
    }
    .introduction {
      font-family: "Baloo Bhai", cursive;
      font-size: 3rem;
      font-weight: 700;
      text-align: center;
      padding-top: 2rem;

      @include media(set-max-w, 800) {
        font-size: 1.6rem;
        padding: 1rem 0
      }
    }
    .youtube {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  
</style>